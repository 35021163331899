

















import { InputSetups } from '@/mixins/input-setups'
import BindTargetService from '@/includes/services/BindTargetService'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import BotDefaultEditorMessage from '@/components/HelpMessages/editor/BotDefaultEditorMessage.vue'
import { setPostBoardConfig } from '@/includes/logic/Bot/BotConfig'
import { Board } from '@/includes/types/Board.types'
import { errorNotification } from "@/includes/services/NotificationService";

import PrivateMessageSettings
  from 'piramis-base-components/src/shared/modules/BotFeedback/PrivateMessageSettings/PrivateMessageSettings.vue'
import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import { BindTargetByCode, BindTargetStatus, BindTargetByCodeRes } from 'piramis-base-components/src/shared/types/BindTarget.types'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import {
  UserActivationActionTypeEnum
} from "piramis-base-components/src/shared/modules/BotFeedback/ActivationAction";
import { BotPmConfig } from 'piramis-base-components/src/shared/modules/BotFeedback/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  data() {
    return {
      BotDefaultEditorMessage,
    }
  },
  components: {
    PrivateMessageSettings,
  }
})
export default class PrivateMessagesSettingsContainer extends Mixins<InputSetups, PlaceholdersMixin>(InputSetups, PlaceholdersMixin) {
  get board(): Board | null {
    return this.$store.getters.activeBoard
  }

  get pmConfig(): BotPmConfig {
    return this.board!.config.pm_config
  }

  get limitTypes() {
    if (this.board!.license_expired) {
      return [ UserActivationActionTypeEnum.Flow ]
    }
  }

  bindTargetByCode(args: BindTargetByCode): Promise<BindTargetByCodeRes | void> {
    return BindTargetService.bindTargetByCode('tg', args)
      .then((res) => {
        if (res.status === BindTargetStatus.Success) {
          this.$store.dispatch('getBoardConfig', this.board!.board)
        }

        return res
      })
      .catch(errorNotification)
  }

  saveConfig(pmConfig: BotPmConfig) {
    const { board, lang, timezone, config } = this.board!

    return setPostBoardConfig(
      board, {
        config: { ...config, pm_config: pmConfig },
        lang,
        timezone,
      }
    )
      .then(res => res)
  }

  gotoFlowsList(): void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  get adminList(): Array<SelectOptionData> {
    return (this.board?.admins ?? [])
      .filter(a => a.limits === null) //admins only
      .map(a => ({
        label: `${ a.name } ${ a.username ? '(' + atSignedLogin(a.username) + ')' : '' }`,
        value: a.user_id
      }))
  }
}
