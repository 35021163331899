





















import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import { SlimBoardInfo, TargetState } from '@/includes/types/Board.types'

import LicenseWizard from 'piramis-base-components/src/components/FlowsTemplates/LicenseWizard.vue'
import { WizardComplete } from 'piramis-base-components/src/components/FlowsTemplates/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    LicenseWizard
  }
})
export default class GettingTestPeriodPrime extends DrawerWidthMixin {
  @Prop() board!: SlimBoardInfo

  isOpen = false

  get channelsOptions(): Array<SelectOption> {
    return this.board.channels
      .filter(c => c.state === TargetState.Board)
      .map(c => ({ label: c.title, value: c.id }))
  }

  createFlowTemplate(board_key: WizardComplete['board_key'], template: WizardComplete['template']) {
    if (board_key && template) {
      this.$baseTemplate.loader.open()

      this.$store.dispatch('createFlowTemplate', {
        board_key,
        ...template
      })
        .then(() => {
          this.isOpen = false
        })
        .finally(() => {
          this.$baseTemplate.loader.close()
        })
    } else {
      this.isOpen = false
    }
  }

  handleComplete(data: WizardComplete) {
    this.$store.dispatch('activateBoardLicence', data.board_key)
      .then(() => {
        this.createFlowTemplate(data.board_key, data.template)
      })
  }
}
