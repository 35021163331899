


















































import AutoReplyActionsSetup from 'piramis-base-components/src/shared/modules/BotFeedback/AutoReplyActions/AutoReplyActionsSetup.vue'
import { AutoReplyActions } from 'piramis-base-components/src/shared/modules/BotFeedback/AutoReplyActions/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'
import { mapActions } from 'vuex'

@Component({
  components: {
    AutoReplyActionsSetup,
    ConfigField,
    HighlightAnchor
  },
  methods: {
    ...mapActions([ 'resolveFlowSelectOption' ])
  }
})
export default class PmCommandsContainer extends Vue {
  resolveFlowSelectOption!: Promise<Array<SelectOption>>

  get bindFeedbackId(): string | null {
    const id = this.$store.state.boardsState.activeBoard!.config.pm_config.feedback_group_id

    if (id) {
      const admin = this.$store.state.boardsState.activeBoard!.admins.find((a) => a.user_id === id)

      if (admin) {
        return atSignedLogin(admin.username)
      }

      return id
    }

    return null
  }

  updateActions(actions: AutoReplyActions) {
    this.$store.commit('SET_COMMANDS', actions)

    this.$store.dispatch('savePostBoardConfig')
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }
}
