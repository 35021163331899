















































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import RunFlowButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RunFlowButton'

import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import AutoReplyActionsSetup
  from 'piramis-base-components/src/shared/modules/BotFeedback/AutoReplyActions/AutoReplyActionsSetup.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import LinkButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import {
  BaseButton
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types";
import { UserActivationActionTypeEnum } from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction'
import {
  AutoReplyAction,
  AutoReplyActions
} from "piramis-base-components/src/shared/modules/BotFeedback/AutoReplyActions/types";
import { BotPmConfig } from "piramis-base-components/src/shared/modules/BotFeedback/types";

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from "lodash";

@Component({
  components: {
    AutoReplyActionsSetup,
    ConfigField,
    HighlightAnchor,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      EditorButtonsHelpView,
    }
  }
})
export default class AutoReplyActionsContainer extends Mixins(ModelSetter, InputSetups, PlaceholdersMixin) {
  showAlert = false

  pmConfigCopy: BotPmConfig | null = null

  get board() {
    return this.$store.state.boardsState.activeBoard
  }

  get limitTypes() {
    if (this.board?.license_expired) {
      return [ UserActivationActionTypeEnum.Flow ]
    }
  }

  get autoReplyActionEditorButtons() {
    return [
      new LinkButtonBuilder(this.$i18n),
      new RunFlowButtonBuilder(this.$i18n, this.resolveFlowSelectOption),
    ]
  }

  @Watch('buttons', { immediate: true })
  buttonsWatcher(buttons: Array<Array<BaseButton>>): void {
    const buttonsText: Array<string> = []

    buttons.forEach((row) => {
      row.forEach((button) => {
        buttonsText.push(button.text)
      })
    })

    const conditions: Array<string> = []

    if (this.pmConfigCopy && this.pmConfigCopy.user_auto_reply_actions) {
      this.pmConfigCopy.user_auto_reply_actions.forEach((action: AutoReplyAction) => {
        action.conditions.forEach((group) => {
          group.forEach((condition) => {
            conditions.push(...condition.values)
          })
        })
      })
    }

    this.showAlert = !conditions.every(c => buttonsText.includes(c.trim())) || (!!buttonsText.length && !conditions.length)
  }

  get buttons(): Array<Array<BaseButton>> {
    if (this.pmConfigCopy) {
      const { initial_message } = this.pmConfigCopy

      if (initial_message && initial_message.length) {
        return initial_message[0].buttons
      }
    }

    return []
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  saveInitialMessage(): void {
    if (this.pmConfigCopy) {
      this.$store.commit('SET_INITIAL_MESSAGE', this.pmConfigCopy.initial_message)

      this.$store.dispatch('savePostBoardConfig')
    }
  }

  updateActions(actions: AutoReplyActions) {
    this.$store.commit('SET_AUTO_REPLY_ACTIONS', actions)

    this.$store.dispatch('savePostBoardConfig')
  }

  created() {
    if (this.board) {
      this.pmConfigCopy = cloneDeep(this.board.config.pm_config)
    }
  }
}
