var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.board && _vm.pmConfigCopy)?_c('div',[_c('highlight-anchor',{staticClass:"mt-5",attrs:{"has-anchor-icon":"","anchor-key":"initial_message"}},[_c('a-card',{attrs:{"title":_vm.$t('initial_message_setting_title')}},[_c('a-alert',[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('initial_message_setting_help_message', [_vm.board.title]))},slot:"message"})]),(_vm.pmConfigCopy.initial_message !== null)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.messageEditorToPlainSetter,
          'args': {
            'model': _vm.pmConfigCopy,
            'key': 'initial_message',
            'hasMedia': true,
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.board.board
            },
            'base-api-url': 'api.presscode.app/cabinet',
            'availableButtonsTypes': _vm.newTextButton,
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'placeholders': _vm.botFeedbackPlaceholders
          }
        }}}):_vm._e(),_c('div',{staticClass:"text-right"},[_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveInitialMessage}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),(_vm.showAlert)?_c('a-alert',{staticClass:"my-5",attrs:{"type":"warning","show-icon":"","message":_vm.$t('warning'),"description":_vm.$t('auto_reply_actions_page_warning_alert')}}):_vm._e(),_c('highlight-anchor',{staticClass:"page-header-anchor-top-position",attrs:{"has-anchor-icon":"","anchor-key":"user_auto_reply_actions"}},[_c('auto-reply-actions-setup',{attrs:{"auto-reply-actions":_vm.board.config.pm_config.user_auto_reply_actions,"flow-options":_vm.resolveFlowSelectOption,"empty-flow-button-callback":_vm.gotoFlowsList,"board-key":_vm.board.board,"editor-buttons":_vm.autoReplyActionEditorButtons,"limit-types":_vm.limitTypes},on:{"update-actions":_vm.updateActions}},[_c('config-field',{attrs:{"slot":"page-title","title":_vm.$t('field_auto_reply_actions_title'),"help-message":_vm.$t('field_auto_reply_actions_help_message')},slot:"page-title"})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }