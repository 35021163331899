
















import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import { Board, SlimBoardInfo } from '../includes/types/Board.types'
import GettingTestPeriodPrime from './Boards/GettingTestPeriodPrime.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    GettingTestPeriodPrime
  },
  data() {
    return {
      hasChangeConfigPermission
    }
  }
})
export default class FreeLicenseActivationAlert extends Vue {
  @Prop() board!: SlimBoardInfo | Board
}
